@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600|Source+Code+Pro:300|Titillium+Web:100,200,300,400,500,600|Roboto+Mono:300,300i,400,400i);
body {
  overflow-x: hidden !important; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

/* change the container width with media query */
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@media only screen and (max-width: 800px) {
  .sub-menu-opened {
    display: block !important; } }

.contentFormat {
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.875;
  color: #656565;
  margin: 0;
  padding: 0; }
  .contentFormat h2 {
    font-size: 3rem;
    line-height: 1.3; }
  .contentFormat h3,
  .contentFormat .h3 {
    font-size: 2.4rem;
    line-height: 1.25; }
  .contentFormat h4,
  .contentFormat .h4 {
    font-size: 2.1rem;
    line-height: 1.286; }
  .contentFormat h5,
  .contentFormat .h5 {
    font-size: 1.6rem;
    line-height: 1.313; }
  .contentFormat h6,
  .contentFormat .h6 {
    font-size: 1.3rem;
    line-height: 1.385;
    text-transform: uppercase;
    letter-spacing: .16rem; }
  .contentFormat input,
  .contentFormat textarea,
  .contentFormat select,
  .contentFormat pre,
  .contentFormat blockquote,
  .contentFormat figure,
  .contentFormat table,
  .contentFormat p,
  .contentFormat ul,
  .contentFormat ol,
  .contentFormat dl,
  .contentFormat form,
  .contentFormat .video-container,
  .contentFormat .cl-custom-select, .contentFormat .p {
    margin-bottom: 3rem; }

format-standard h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "librebaskerville-bold", serif;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

.descr
format-standard h1,
.descr
format-standard h2,
.descr
format-standard h3,
.descr
format-standard h4,
.descr
format-standard .h1,
.descr
format-standard .h2,
.descr
format-standard .h3,
.descr
format-standard .h4 {
  margin-top: 3em;
  margin-bottom: 1.8rem; }

.descr
format-standard h5,
.descr
format-standard .h5,
.descr
format-standard h6,
.descr
format-standard .h6 {
  margin-top: 4.2rem;
  margin-bottom: 1.5rem; }

img.pull-left {
  margin: 1.5rem 3rem 0 0; }


html {
    margin: 0px;
    margin-left: -1px;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

