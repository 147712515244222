@import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600|Source+Code+Pro:300|Titillium+Web:100,200,300,400,500,600|Roboto+Mono:300,300i,400,400i');

body {
    overflow-x: hidden !important;
}
.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

/* change the container width with media query */
// .homepage-container {
//   width: 1200px;
//   margin-top: 0;
//   margin-left: auto;
//   margin-right: auto;
// }

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
